<template>
  <div class="content">
    <h1 class="mainTitle">指云端云影像</h1>
    <h2 class="subtitle">CLOUD IMAGE</h2>
    <div class="describe">
      <div class="describeTitle">一指存云,互惠三端</div>
      <p class="info">
        全量影像数据保存在云端，支持下载查阅，便于携带、转诊。影像后处理技术可以更好的显示数据和诊断信息，为医生提供立体的显示手段和定量分析工具
      </p>
    </div>
    <div class="totleDicom">
      <el-image
        fit="cover"
        class="totalDicomImg"
        :src="require('@/assets/images/web/product/yxtux_.png')"
      />
      <!-- <el-image
        fit="cover"
        class="totalDicomImg"
        :src="require('@/assets/images/web/product/sj2@2x.png')"
      /> -->
      <div class="totalDicomDesc">
        <div class="title">全量DICOM影像源文件</div>
        <p class="protocol">DICOM3.0国际标准</p>
        <div class="items">
          <div class="item">
            <el-image
              fit="cover"
              :src="require('@/assets/images/web/product/dicom.png')"
            ></el-image>
            <p>诊断级别，无损图像</p>
          </div>
          <div class="item">
            <el-image
              fit="cover"
              :src="require('@/assets/images/web/product/cloudstorage.png')"
            ></el-image>
            <p>云端存储，长期保存</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container productFunctionContainer">
      <div class="productFunction">
        <div>功能特点</div>
        <h4>影像存储在云端，在云端对图像处理和分析</h4>
        <div class="functionCards">
          <div class="card card1">
            <div class="icon"></div>
            <p class="cardTitle">影像调阅</p>
            <p class="cardDesc">PACS全量图像、无损压缩、<br />秒级呈现</p>
          </div>
          <div class="card card2">
            <div class="icon"></div>
            <p class="cardTitle">图像辅助处理</p>
            <p class="cardDesc">
              ·MPR、CPR、MIP、SSD、VR、VE 三维图像处理·三维裁剪切割，一键去床·3D
              MPR浏览、PEPTCT融合、三维MPR融合
            </p>
          </div>
          <div class="card card3">
            <div class="icon"></div>
            <p class="cardTitle">临床应用功能</p>
            <p class="cardDesc">
              影像智能分析、三维重建、高密度投影、一键去骨、虚拟手术、3D打印、实例教学等
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container three-dContainer">
      <div class="three-d">
        <div class="three-dImage">
          <el-image
            fit="cover"
            class="totalDicomImg"
            :src="require('@/assets/images/web/product/three-d.gif')"
          />
        </div>
        <div class="three-dDesc">
          <div class="three-dTitle">三维重建</div>
          <p class="three-dInfo">
            三维重建技术可以更好的显示数据和诊断信息，为医生提供立体的显示手段和定量分析工具
          </p>
        </div>
      </div>
    </div>
    <div class="container cloudChaoshengContainer">
      <div class="cloudChaosheng">
        <div class="cloudChaoshengDesc">
          <div class="cloudChaoshengDescTitle">数字云超声系统</div>
          <div class="cloudChaoshengDescInfo">
            患者超声图像数据以无损图像或者动态视频的方式存储在云端，患者可通过手机随时调阅。
          </div>
          <div class="cloudChaoshengMeaning">
            <el-image
              fit="cover"
              class="cloudChaoshengMeaningCard"
              :src="require('@/assets/images/web/product/shengm.png')"
            />
            <div style="background: #26b59c" class="cloudChaoshengMeaningCard">
              <div class="cloudChaoshengMeaningCardTitle">记录生命的奇迹</div>
              <p class="cloudChaoshengMeaningCardInfo">
                对于孕期胎儿生长记录、宫内发育监测，有着特别的意义。其余超声检查也能根据医院的采集情况无损呈现在患者手机，长久保存。
              </p>
            </div>
            <div style="background: #275fd0" class="cloudChaoshengMeaningCard">
              <div class="cloudChaoshengMeaningCardTitle">云超声的医学价值</div>
              <p class="cloudChaoshengMeaningCardInfo">
                医生可通过云超声进行图像查询、诊断报告调阅、图像诊断、图像分析等。
              </p>
            </div>
            <el-image
              fit="cover"
              class="cloudChaoshengMeaningCard"
              :src="require('@/assets/images/web/product/medicalMean.png')"
            />
          </div>
        </div>

        <div class="cloudChaoshengMeaningImg"></div>
      </div>
    </div>
    <div class="lookDicom">
      <div class="lookDicomContent">
        <div class="lookDicomDesc">
          <div class="lookDicomTitle">影像云端阅片服务</div>
          <p class="lookDicomInfo">
            影像阅片可以实现全科影像秒级处理分析，满足多种智能终端设备的快速调阅、随时随地移动阅片和无胶片影像等应用需求，
            并为影像大数据、AI辅助阅片等提供了基础条件。
          </p>
        </div>
        <div class="lookDicomImg"></div>
      </div>
    </div>
    <div class="container cloudPacsContainer">
      <div class="cloudPacs">
        <div class="cloudPacsDesc">
          <div class="cloudPacsDescImg">
            <el-image class="img" fit="cover" :src="require('@/assets/images/web/product/pace.png')" ></el-image>
          </div>
          
          <div class="cloudPacsDescInfo">
            <div class="cloudPacsDescTitle">全流程云PACS影像业务</div>
            <p class="cloudPacsDescTitleText">
              基于影像云平台为医院提供云PACS解决方案，向影像科医生提供从预约登记、排队叫号、拍片检查、阅片诊断的全流程影像业务应用，以及影像后处理和质控管理服务。
            </p>
          </div>
        </div>
        <div class="cloudPacsFlow">
          <div>
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/yuyue.png')"
            />
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/pd.png')"
            />
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/papian.png')"
            />
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/zduan.png')"
            />
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/hchuli.png')"
            />
            <el-image
              fit="cover"
              class="cloudPacsFlowIcon"
              :src="require('@/assets/images/web/product/zl.png')"
            />
          </div>
          <div class="cloudPacsFlowLine">
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine transparent"></div>
                <div class="dot"></div>
                <div class="rightLine"></div>
              </div>
              <p>预约登记</p>
            </div>
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine"></div>
                <div class="dot"></div>
                <div class="rightLine"></div>
              </div>
              <p>排队叫号</p>
            </div>
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine"></div>
                <div class="dot"></div>
                <div class="rightLine"></div>
              </div>
              <p>拍片检查</p>
            </div>
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine"></div>
                <div class="dot"></div>
                <div class="rightLine"></div>
              </div>
              <p>阅片诊断</p>
            </div>
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine"></div>
                <div class="dot"></div>
                <div class="rightLine"></div>
              </div>
              <p>影像后处理</p>
            </div>
            <div class="cloudPacsFlowPart">
              <div class="dotAndLine">
                <div class="leftLine"></div>
                <div class="dot"></div>
                <div class="rightLine transparent"></div>
              </div>
              <p>质控管理</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container characteristicContainer">
      <div class="characteristic">
        <div class="characteristicTitle">产品特点</div>
        <p class="characteristicDesc">
          云影像是对电子胶片的云化存储，通过将患者的影像电子胶片和影像诊断报告进行云存储，由医疗机构通过短信、微信等方式向
          患者提供电子链接或二维码信息，患者即可通过手机查看影像胶片和诊断报告。
        </p>
        <div class="characteristicCards">
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/zhenduan.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">方便诊断</div>
              <p class="characteristicCardDesc">
                医生则通过云胶片可以进行影像查询、影像档案、
                影像归档、诊断报告调阅、影像分析等。
              </p>
            </div>
          </div>
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/jieyue.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">节约成本</div>
              <p class="characteristicCardDesc">
                云胶片可以为患者节省胶片打印、领取的金钱和时间成本，方便携带和共享。
              </p>
            </div>
          </div>
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/tisheng.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">提升效率</div>
              <p class="characteristicCardDesc">
                帮助医疗机构降低胶片的打印成本和硬件成本，提升医患沟通效率。
              </p>
            </div>
          </div>
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/gongying.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">实现共赢</div>
              <p class="characteristicCardDesc">
                给政府“双向转诊、分级诊疗”政策的推进提供基础，实现医患政府“三方共赢”。
              </p>
            </div>
          </div>
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/gongneng.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">功能丰富</div>
              <p class="characteristicCardDesc">
                指云端医疗提供影像解读、词条检验等特色医疗服务，并可根据需求定制解决方案。
              </p>
            </div>
          </div>
          <div class="characteristicCard">
            <div class="characteristicCardImg">
              <img
                :src="require('@/assets/images/web/product/xingneng.png')"
                alt=""
              />
            </div>
            <div class="characteristicCardInfo">
              <div class="characteristicCardTitle">性能稳定</div>
              <p class="characteristicCardDesc">
                指云端技术团队持续开发、维护与创新，致力于高品质服务基层医院和患者。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content {
  .container {
    width: 100%;
    overflow: hidden;
  }
  .mainTitle {
    text-align: center;
    font-family: 'PingFang SC';
    margin: 0 auto;
    margin-top: 100px;
    color: #29262a;
    font-weight: bold;
    font-size: 49px;
  }
  .subtitle {
    text-align: center;
    font-size: 18px;
    font-family: AngsanaUPC;
    font-weight: 400;
    color: #afb4bc;
    margin: 0 auto;
    margin-top: 33px;
    letter-spacing: 7px;
  }
  .describe {
    overflow: hidden;
    margin: 0 auto;
    margin-top: 69px;
    width: 1200px;
    height: 412px;
    background: url('../../../../assets/images/web/product/yunyingxiang.png')
      no-repeat;
    background-size: 100% 100%;
    .describeTitle {
      letter-spacing: 2px;
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-top: 113px;
      color: white;
      font-size: 45px;
      font-weight: bold;
      font-family: 'PingFang SC';
    }
    .info {
      color: white;
      margin: 0 auto;
      margin-top: 56px;
      width: 1030px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Source Han Sans CN';
      text-align: center;
    }
  }
  .totleDicom {
    display: flex;
    flex-direction: row;
    width: 1200px;
    height: 500px;
    margin: 0 auto;
    margin-top: 112px;
    .totalDicomImg {
      width: 508px;
      height: 500px;
    }
    .totalDicomDesc {
      margin-left: 56px;
      width: 570px;
      .title {
        color: #29262a;
        width: 570px;
        font-size: 45px;
        margin-top: 0px;
        font-family: PingFang SC;
        font-weight: bold;
      }
      .protocol {
        margin-top: 62px;
        color: #646a73;
        font-weight: 400;
        font-size: 25px;
        font-family: 'Microsoft YaHei';
      }
      .items {
        margin-top: 107px;
        display: flex;
        flex-direction: row;
        .item {
          &:last-child {
            margin-right: 0px;
          }
          width: 242px;
          display: flex;
          margin-right: 81px;
          flex-direction: column;
          align-items: center;
          p {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #999999;
            margin-top: 36px;
          }
        }
      }
    }
  }
  .productFunctionContainer {
    height: 1004px;
    background: #f9f9f9;
    margin-top: 85px;
    .productFunction {
      width: 1200px;
      margin: 0 auto;
      height: 1004px;
      div {
        width: 204px;
        margin: 0 auto;
        margin-top: 106px;
        color: #29262a;
        font-size: 45px;
        font-family: PingFang SC;
        font-weight: bold;
      }
      h4 {
        width: 560px;
        margin: 0 auto;
        margin-top: 34px;
        color: #646a73;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        letter-spacing: 3px;
      }
      .functionCards {
        width: 1200px;
        height: 581px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .card1 {
          &:hover {
            .icon {
              background: url('../../../../assets/images/web/product/yxdy_y.png')
                no-repeat;
              background-size: 100%;
            }
          }
          .icon {
            background: url('../../../../assets/images/web/product/yxdy_w.png')
              no-repeat;
            background-size: 100%;
          }
        }
        .card2 {
          &:hover {
            .icon {
              background: url('../../../../assets/images/web/product/txcl_y.png')
                no-repeat;
              background-size: 100%;
            }
          }
          .icon {
            background: url('../../../../assets/images/web/product/txcl_w.png')
              no-repeat;
            background-size: 100%;
          }
        }
        .card3 {
          &:hover {
            .icon {
              background: url('../../../../assets/images/web/product/lcygn_y.png')
                no-repeat;
              background-size: 100%;
            }
          }
          .icon {
            background: url('../../../../assets/images/web/product/lcygn_w.png')
              no-repeat;
            background-size: 100%;
          }
        }
        .card {
          &:hover {
            transform: translate(0px, -20px);
            background: #0065b3;
            box-shadow: 0px 7px 38px 0px rgba(84, 10, 179, 0.24);
            .cardTitle {
              color: white;
              font-weight: 700;
            }
            .cardDesc {
              color: white;
            }
          }
          &:first-child {
            .cardDesc {
              width: 260px;
            }
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 388px;
          height: 581px;
          background: white;
          box-shadow: 0px 7px 38px 0px rgba(12, 41, 72, 0.19);
          border-radius: 10px;
          .icon {
            width: 115px;
            height: 115px;
            margin: 0 auto;
            margin-top: 106px;
          }
          .cardTitle {
            color: #2c2c2c;
            font-size: 22px;
            font-weight: 400;
            font-family: PingFang SC;
            letter-spacing: 10px;
            margin-top: 33px;
            font-weight: 700;
          }
          .cardDesc {
            text-align: justify;
            width: 320px;
            letter-spacing: 2px;
            color: #999999;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            margin-top: 55px;
          }
        }
      }
    }
  }
  .three-dContainer {
    overflow: hidden;
    height: 732px;
    background: url('../../../../assets/images/web/product/chongjian@2x.png')
      no-repeat;
    background-size: 100% 100%;
    .three-d {
      width: 1200px;
      height: 388px;
      margin: 0 auto;
      margin-top: 184px;
      display: flex;
      flex-direction: row;
      .three-dImage {
        width: 737px;
        height: 388px;
        background: url('../../../../assets/images/web/product/b_5bc0658ead.png');
        background-size: 100% 100%;
      }
      .three-dDesc {
        margin-left: 100px;
        width: 433px;
        height: 388px;
        .three-dTitle {
          font-size: 52px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .three-dInfo {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 45px;
          margin-top: 67px;
        }
      }
    }
  }
  .cloudChaoshengContainer {
    overflow: hidden;
    height: 924px;
    background: url('../../../../assets/images/web/product/chaosheng-bg.png');
    background-size: auto 100%;
    .cloudChaosheng {
      width: 1200px;
      height: 678px;
      margin: 0 auto;
      margin-top: 125px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .cloudChaoshengDesc {
        height: 718px;
        width: 678px;
        margin-right: 66px;
        display: flex;
        flex-direction: column;
        .cloudChaoshengDescTitle {
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .cloudChaoshengDescInfo {
          margin-top: 50px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c7c5c5;
          line-height: 40px;
        }
      }
      .cloudChaoshengMeaning {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        width: 719px;
        height: 405px;
        flex-wrap: wrap;
        margin-top: 114px;
        .cloudChaoshengMeaningCard {
          width: 357px;
          height: 203px;
          .cloudChaoshengMeaningCardTitle {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            padding-left: 25px;
            padding-top: 40px;
          }
          .cloudChaoshengMeaningCardInfo {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 25px;
            margin-top: 30px;
            padding-left: 25px;
            padding-right: 25px;
          }
        }
      }
      .cloudChaoshengMeaningImg {
        margin-top: 105px;
        height: 589px;
        width: 300px;
        background: url('../../../../assets/images/web/product/sj_ycs_.png')
          no-repeat;
        background-size: 100% 100%;
        margin-left: 155px;
      }
    }
  }
  .lookDicom {
    width: 100%;
    height: 601px;
    overflow: hidden;
    margin: 0 auto;
    .lookDicomContent {
      width: 1200px;
      height: 376px;
      margin: 0 auto;
      margin-top: 82px;
      display: flex;
      flex-direction: row;
      .lookDicomDesc {
        margin-top: 82px;
        width: 493px;
        .lookDicomTitle {
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #29262a;
        }
        .lookDicomInfo {
          margin-top: 62px;
          font-size: 18px;
          font-family: 'Microsoft YaHei';
          font-weight: 400;
          color: #646a73;
          line-height: 47px;
          text-align: justify;
        }
      }
      .lookDicomImg {
        width: 625px;
        height: 376px;
        margin-left: 118px;
        background: url('../../../../assets/images/web/product/yxydyp_.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .cloudPacsContainer {
    overflow: hidden;
    height: 1115px;
    background: url('../../../../assets/images/web/product/5751366d6ee9d_1024.png')
      no-repeat;
    background-size: cover;
    .cloudPacs {
      width: 1200px;
      height: 866px;
      margin: 0 auto;
      margin-top: 138px;
      .cloudPacsDesc {
        width: 1200px;
        height: 434px;
        background: white;
        display: flex;
        flex-direction: row;
        .cloudPacsDescImg {
          width: 520px;
          height: 434px;
          background: linear-gradient(3deg, #2680b2, #2681b1, #264db4, #254bb2);
          .img{
            width: 442px;
            height: 314px;
            margin-top: 47px;
            margin-left: 34px;
          }
        }
        .cloudPacsDescInfo {
          margin: 0 auto;
          flex: 1;
          margin-top: 73px;
          padding-left: 35px;
          padding-right: 67px;
        }
        .cloudPacsDescTitle {
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #29262a;
        }
        .cloudPacsDescTitleText {
          margin-top: 45px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #646a73;
          line-height: 48px;
          text-align: justify;
        }
      }
      .cloudPacsFlow {
        height: 343px;
        width: 1200px;
        margin-top: 89px;
        .cloudPacsFlowIcon {
          &:last-child {
            margin-right: 0px;
          }
          display: inline-block;
          width: 164px;
          width: 164px;
          margin-right: 40px;
        }
        .cloudPacsFlowLine {
          width: 204px;
          height: 26px;
          display: flex;
          flex-direction: row;
          .cloudPacsFlowPart {
            &:last-child {
              width: 164px;
              p {
                padding-right: 0px;
              }
              .dotAndLine {
                width: 164px;
                .rightLine {
                  width: 0px;
                }
              }
            }
            width: 204px;
            height: 102px;
            p {
              margin-top: 49px;
              font-size: 25px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #11e2cb;
              line-height: 36px;
              text-align: center;
              padding-right: 40px;
            }
            .dotAndLine {
              margin-top: 48px;
              width: 204px;
              height: 26px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              .dot {
                background: #26b59c;
                width: 26px;
                height: 26px;
                border-radius: 50%;
              }
              .rightLine {
                width: 120px;
                height: 4px;
                background: #26b59c;
              }
              .leftLine {
                width: 69px;
                height: 4px;
                background: #26b59c;
              }
              .transparent {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
  .characteristicContainer {
    overflow: hidden;
    height: 1200px;
    .characteristic {
      width: 1200px;
      height: 1048px;
      margin: 0 auto;
      margin-top: 68px;
      .characteristicTitle {
        text-align: center;
        font-size: 45px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #29262a;
      }
      .characteristicDesc {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #646a73;
        line-height: 47px;
        text-align: center;
        margin-top: 47px;
        letter-spacing: 3px;
      }
      .characteristicCards {
        width: 1200px;
        height: 767px;
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        .characteristicCard {
          box-shadow: 0px 7px 38px 0px rgba(12, 41, 72, 0.19);
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 580px;
          height: 233px;
          margin-top: 18px;
          border: 1px solid #fff;
          .characteristicCardImg {
            width: 212px;
            height: 198px;
            margin-left: 18px;
            margin-right: 22px;
            margin-top: 18px;
            img {
              width: 212px;
              height: 198px;
            }
          }
          .characteristicCardInfo {
            width: 310px;
            height: 198px;
            .characteristicCardTitle {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #3c3c3c;
              margin-top: 45px;
            }
            .characteristicCardDesc {
              margin-top: 17px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 33px;
            }
          }
        }
        .characteristicCard:hover {
          border: 1px solid #428bf9;
        }
      }
    }
  }
}
</style>
